import React from "react"

const Footer = () => (
  <footer className="footer py-3">
    <div className="container">
      <div className="row">
        <div className="col col-md-6 text-white text-capitalize">
          <h4>
            all rights reserved &copy;{new Date().getFullYear().toString()}
          </h4>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
