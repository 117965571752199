import React, { Component } from "react"
import { cx } from "emotion"
import { Link } from "gatsby"

export default class Navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      navbarOpen: false,
      links: [
        { id: 1, path: "/", text: "home" },
        { id: 2, path: "/about", text: "about" },
      ],
    }
    this.navbarHandler = this.navbarHandler.bind(this)
  }

  navbarHandler() {
    this.setState(prevState => {
      return { navbarOpen: !prevState.navbarOpen }
    })
  }

  render() {
    const { navbarOpen, links } = this.state

    return (
      <nav className="navbar navbar-expand-sm bg-light navbar-light">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <h2 className="h4 mb-0 logo">
              Gist<span className="font-weight-bold">Pages</span>
            </h2>
            {/* https://www.iconfinder.com/icons/185113/coffee_streamline_icon
          Creative Commons (Attribution 3.0 Unported);
          https://www.iconfinder.com/webalys */}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.navbarHandler}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={cx("collapse navbar-collapse navbar-right", {
              show: navbarOpen,
            })}
          >
            <ul className="navbar-nav">
              {links.map(link => (
                <li key={link.id} className="nav-item">
                  <Link to={link.path} className="nav-link text-capitalize">
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
